import { useState } from "react";
import PlusIcon from "../../assets/icons/PlusIcon";
import MinusIcon from "../../assets/icons/MinuIcon";
import PrimaryPlusIcon from "../../assets/icons/PrimaryPlusIcon";
import PrimaryMinusIcon from "../../assets/icons/PrimaryMinusIcon";

const Accordion = ({ title, content, isActive, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="border-1 border-b py-5">
      <button
        className={`flex justify-between items-center w-full py-3 text-[1.35rem] font-medium font-instrument ${
          isActive || isHovered ? "text-primary" : ""
        }`}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {title}
        <span className="text-2xl font-bold">
          {isHovered ? (
            isActive ? (
              <PrimaryMinusIcon />
            ) : (
              <PrimaryPlusIcon />
            )
          ) : isActive ? (
            <MinusIcon />
          ) : (
            <PlusIcon />
          )}
        </span>
      </button>
      {isActive && <p className="text-gray-600">{content}</p>}
    </div>
  );
};

export default Accordion;
