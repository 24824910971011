import React from "react";
import Card from "../cards/Cards";
import { case_studies, details } from "./data";
import Button from "../Button/Button";

const CaseStudies = () => {
  return (
    <section className="lg:py-10  w-11/12 mx-auto">
      <div className="container mx-auto text-center">
        <h2 className="lg:text-5xl text-4xl font-medium mb-4 text-gradient-radial font-cabinet">
          {details[0].title}
        </h2>
       
        <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-16 gap-8">
          {case_studies.map((case_study) => (
            <Card
            className="shadow-none"
            imageClass="bg-white p-0"
            contentClass="px-7"
            linkArea='px-7'
              animateOnHover={false}
              key={case_study.id}
              image={case_study.image}
              title={case_study.title}
              description={case_study.description}
              link={case_study.link}
            />
          ))}
        </div>
        <div><Button variant="primary" label={'View Portfolio'} curve="lg" className="mt-6"/></div>
      </div>
    </section>
  );
};

export default CaseStudies;
