import React from "react";
import Card from "../cards/Cards";
import { creations, details } from "./data";
import Button from "../Button/Button";

const Creations = () => {
  return (
    <section className="lg:py-20  w-11/12 mx-auto">
      <div className="container mx-auto text-center">
        <h2 className="lg:text-5xl text-4xl font-medium mb-4 text-gradient-radial font-cabinet">
          {details[0].title}
        </h2>
        <p className="text-xl mb-12 font-instrument">{details[1].desc}</p>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-16 gap-8">
          {creations.map((creation) => (
            <Card
            className="shadow-none"
            imageClass="bg-white p-0"
            contentClass="px-7"
            linkArea='px-7'
              animateOnHover={false}
              key={creation.id}
              image={creation.image}
              title={creation.title}
              description={creation.description}
              link={creation.link}
            />
          ))}
        </div>
        <div><Button variant="primary" label={'View Portfolio'} curve="lg" className="mt-6"/></div>
      </div>
    </section>
  );
};

export default Creations;
