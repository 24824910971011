// ProcessSection.js
import React from 'react';
import ProcessCard from '../cards/ProcessCard';
import { processCards,progressDetails } from './data';

const ProcessSection = () => {
  return (
    <div className=" text-white py-16  ">
  <div className='p-4'>
  <h2 className="lg:text-5xl text-4xl font-medium font-cabinet text-center text-orange-500 rounded mb-4">{progressDetails[0].title}</h2>
  <p className="text-xl mb-12 font-instrument text-title text-center">{progressDetails[0].description}</p>
  </div>
      
      <div className='p-4 lg:w-11/12 w-full bg-cardBg rounded-lg mx-auto'>
      <div className=" mb-10">
        <div className="flex flex-col sm:flex-row gap-8">
          {processCards.slice(0, 2).map((card, index) => (
            <ProcessCard
              key={index}
              title={card.title}
              description={card.description}
              imgSrc={card.imgSrc}
              customWidth={card.customWidth}
            />
          ))}
        </div>
      </div>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8  ">
        {processCards.slice(2).map((card, index) => (
          <ProcessCard
            key={index}
            title={card.title}
            description={card.description}
            imgSrc={card.imgSrc}
            customWidth={card.customWidth}
          />
        ))}
      </div>
      </div>
    </div>
  );
};

export default ProcessSection;
