import React from "react";
import { scrollingItems } from "./data";

const ScrollingText = () => {
  return (
    <div className="relative overflow-hidden whitespace-nowrap w-[calc(100vw-40px)]">
      <div className="animate-scroll flex space-x-8 items-center">
        {scrollingItems.map((item, index) => (
          <div key={index} className="flex items-center space-x-4">
            <img
              src={item.img}
              alt="img"
              className="w-6 h-6 object-cover rounded-full"
            />
            <span className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-800">
              {item.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingText;
