import React from 'react';

const DateIcon = ({ width = '35px', height = '35px', fillColor = '#000000', primaryStrokeColor = '#000000', secondaryFillColor = '#fff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    id="date"
    data-name="Flat Line"
    className="icon flat-line"
  >
    <path
      id="secondary"
      d="M3,10H21a0,0,0,0,1,0,0V20a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V10A0,0,0,0,1,3,10Z"
      style={{ fill: secondaryFillColor, strokeWidth: 2 }}
    />
    <path
      id="primary"
      d="M20,21H4a1,1,0,0,1-1-1V10H21V20A1,1,0,0,1,20,21ZM21,6a1,1,0,0,0-1-1H4A1,1,0,0,0,3,6v4H21ZM16,3V7M8,3V7"
      style={{ fill: 'none', stroke: primaryStrokeColor, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2 }}
    />
  </svg>
);

export default DateIcon;
