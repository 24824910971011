import React from "react";

const TickIcon = ({ color = "#0A0A0A" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3334 32C20.5768 32 24.6465 30.3143 27.6471 27.3137C30.6477 24.3131 32.3334 20.2435 32.3334 16C32.3334 11.7565 30.6477 7.68687 27.6471 4.68629C24.6465 1.68571 20.5768 0 16.3334 0C12.0899 0 8.02025 1.68571 5.01967 4.68629C2.01908 7.68687 0.333374 11.7565 0.333374 16C0.333374 20.2435 2.01908 24.3131 5.01967 27.3137C8.02025 30.3143 12.0899 32 16.3334 32ZM23.7474 13.414C24.1117 13.0368 24.3133 12.5316 24.3087 12.0072C24.3042 11.4828 24.0938 10.9812 23.723 10.6104C23.3522 10.2395 22.8506 10.0292 22.3262 10.0247C21.8018 10.0201 21.2966 10.2217 20.9194 10.586L14.3334 17.172L11.7474 14.586C11.3702 14.2217 10.865 14.0201 10.3406 14.0247C9.81618 14.0292 9.31455 14.2395 8.94374 14.6104C8.57292 14.9812 8.36258 15.4828 8.35803 16.0072C8.35347 16.5316 8.55506 17.0368 8.91937 17.414L12.9194 21.414C13.2944 21.7889 13.803 21.9996 14.3334 21.9996C14.8637 21.9996 15.3723 21.7889 15.7474 21.414L23.7474 13.414Z"
        fill={color}
      />
    </svg>
  );
};

export default TickIcon;
