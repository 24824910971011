import React, { useState } from "react";
import ChatIcon from "../../assets/icons/ChatIcon";
import Popup from "../../components/Popup/Popup";

const Chat = () => {
  const [showPopup, setShowPopup] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <button
        onClick={scrollToTop}
        className="fixed bottom-4 right-4 p-0 text-white rounded-full  focus:outline-none"
        aria-label="Scroll to top"
      >
        <ChatIcon />
      </button>

      {showPopup && (
        <Popup
          message="Hey! Welcome to Company Name !If you have any questions, we are happy to help! :)"
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Chat;
