
import React from 'react';
import Button from '../Button/Button';
import {journeyData} from "./data"

const StoryArea = () => {
  return (
    <section className="lg:py-10 py-10 my-20 lg:mt-10 lg:mb-20 w-11/12 mx-auto bg-journey-image bg-no-repeat bg-cover rounded-2xl">
      <div className="container mx-auto text-center px-10">
        <h2 className="lg:text-5xl text-4xl font-medium mb-4 text-white font-cabinet">{journeyData.title}</h2>
        <p className="lg:text-xl text-lg lg:mb-8 mb-10 font-instrument text-white">{journeyData.desc}</p>
        
        <Button variant="secondary" label={journeyData.btn} curve='lg'className='text-black bg-white'/>
      </div>
    </section>
  );
};

export default StoryArea;
