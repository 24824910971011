import React from 'react';

const ChatIcon = ({ color = "#FF3D00" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 84 84" fill="none">
    <g filter="url(#filter0_dd_170_747)">
      <rect x="12" y="10" width="60" height="60" rx="30" fill={color} shape-rendering="crispEdges"/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.7162 28.4889C56.4616 26.9135 55.5114 25.9649 53.9805 25.7484C53.9432 25.7395 50.2151 25.0243 44.3643 25.0243C38.5151 25.0243 34.787 25.7395 34.7619 25.7451C33.5643 25.9154 32.7292 26.5292 32.2954 27.5224C34.8025 27.2453 37.3233 27.1091 39.8457 27.1146C45.5165 27.1146 49.2892 27.7665 49.843 27.8686C52.3395 28.2432 54.0754 29.9751 54.4954 32.513C54.677 33.34 55.2511 36.2476 55.2511 39.7186C55.2511 41.577 55.0841 43.2822 54.9041 44.5851C55.8932 44.1668 56.516 43.3284 56.7122 42.117C56.7187 42.087 57.4362 39.0246 57.4362 35.2941C57.4362 31.5643 56.7187 28.5019 56.7146 28.4881"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M52.1976 32.9135C51.943 31.3373 50.9919 30.3887 49.4619 30.1714C49.4246 30.1633 45.6965 29.4497 39.8465 29.4497C33.9973 29.4497 30.2684 30.1633 30.244 30.1697C28.7011 30.3887 27.7508 31.3373 27.5003 32.8965C27.4921 32.9265 26.7754 35.9889 26.7754 39.7195C26.7754 43.4492 27.4921 46.5116 27.4962 46.5246C27.7508 48.1008 28.7011 49.0495 30.2319 49.2668C30.2619 49.2724 32.6805 49.7354 36.664 49.9162L39.0381 54.0287C39.12 54.1706 39.2378 54.2885 39.3797 54.3705C39.5216 54.4524 39.6826 54.4956 39.8465 54.4956C40.0103 54.4956 40.1713 54.4524 40.3132 54.3705C40.4551 54.2885 40.573 54.1706 40.6549 54.0287L43.0297 49.917C47.0132 49.7354 49.4294 49.2733 49.4497 49.2684C50.9919 49.0495 51.943 48.1008 52.1935 46.5416C52.2016 46.5116 52.9176 43.4492 52.9176 39.7195C52.9176 35.9889 52.2008 32.9265 52.1976 32.9135Z"
        fill="white"
      />
    </g>
    <defs>
      <filter id="filter0_dd_170_747" x="0" y="0" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="6"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_170_747"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_170_747" result="effect2_dropShadow_170_747"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_170_747" result="shape"/>
      </filter>
    </defs>
  </svg>
);

export default ChatIcon;
