import React from "react";

const UIUXExpertise = () => {
  const expertiseData = [
    {
      title: "User Experience Design (UX)",
      description:
        "Creating unique designs that are centred around the mindset of your target segment and ensuring seamless flow.",
    },
    {
      title: "User Interface Design (UI)",
      description:
        "Our UI design process is aimed at creating functional digital interfaces that attract and retain users.",
    },
    {
      title: "Cross-platform Experience Design",
      description:
        "We make sure that the design is aligned across all the platforms to ensure uniformity in the customer experience.",
    },
    {
      title: "Interaction Design",
      description:
        "Designing interactive digital products, environments, systems, and services according to user behavior.",
    },
    {
      title: "UI/UX Consulting & Auditing",
      description:
        "Get the best consulting on UI/UX design services to improve the design and usability of your digital products.",
    },
    {
      title: "Design System",
      description:
        "Systematic approach to design and ensure consistency, efficiency, and scalability across different platforms.",
    },
  ];

  return (
    <div className="w-11/12 mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-medium font-cabinet text-center mb-6">
        Our UI/UX expertise
      </h2>
      <p className="text-center text-disabledText text-base font-medium mb-10 font-instrument">
        We define our process through every step. We leverage the latest tools
        and technologies for efficiency and to accelerate productivity.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {expertiseData.map((item, index) => {
     
          let borderClasses = " border-gray-300 p-4"; 
               
          if (index < 3) borderClasses += " border-b"; 
          if (index % 3 !== 2) borderClasses += " border-r"; 
          
          return (
            <div key={index} className={borderClasses}>
              <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UIUXExpertise;
