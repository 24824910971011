import { useState } from 'react';
import Accordion from '../Accordin/Accordin'; 

const Process = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const data = [
    {
      title: 'Research',
      content:
        'As the top provider of UI/UX design services in Kerala, we examine the existing system, understand the industry benchmark and fetch the latest design principles, trends, and guidelines in UI/UX.',
    },
    {
      title: 'Ideation / Brainstorming',
      content: 'Brief description for Ideation/Brainstorming.',
    },
    {
      title: 'Wireframes',
      content: 'Brief description for Wireframes.',
    },
    {
      title: 'Design',
      content: 'Brief description for Design.',
    },
    {
      title: 'High-Fidelity Prototyping',
      content: 'Brief description for High-Fidelity Prototyping.',
    },
    {
      title: 'User Feedback',
      content: 'Brief description for High-Fidelity Prototyping.',
    },
    {
      title: 'Design Handoff',
      content: 'Brief description for High-Fidelity Prototyping.',
    },
    {
      title: 'Analysis and integration',
      content: 'Brief description for High-Fidelity Prototyping.',
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="lg:w-11/12 mx-auto py-10 px-5">
      <h2 className="text-center text-3xl font-medium mb-6 font-cabinet">Our process</h2>

      {data.map((item, index) => (
        <Accordion
          key={index}
          title={item.title}
          content={item.content}
          isActive={activeIndex === index}
          onClick={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
};

export default Process;
