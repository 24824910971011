import React, { useState } from "react";

const Card = ({
  image,
  title,
  description,
  link,
  className = "",
  imageClass = "",
  titleClass = "",
  descriptionClass = "",
  linkClass = "",
  hoverClass = "",
  animateOnHover = true,
  displayLinkOnHover = true,
  contentClass="",
  linkArea='',
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`relative bg-white shadow-md rounded-lg flex flex-col items-center w-full mx-auto overflow-hidden group ${className}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={`pt-4 px-4 bg-[#F0F4F7] rounded-lg w-full ${imageClass}`}>
        <div className="bg-white pt-2 px-2 rounded-lg">
          <img
            src={image}
            alt={title}
            className="object-cover mx-auto rounded-lg h-60"
          />
        </div>
      </div>
      <div
        className={`p-5 w-full flex flex-col items-start transition-transform duration-300 ${contentClass} ${
          animateOnHover && hovered ? `${hoverClass} mt-[-2rem] bg-white` : ""
        }`}
      >
        <h3
          className={`text-xl font-medium mb-2 font-cabinet ${titleClass}`}
        >
          {title}
        </h3>
        <p
          className={`btnSecondary text-start font-instrument text-base mb-4 ${descriptionClass}`}
        >
          {description}
        </p>
        {displayLinkOnHover && (
          <a
            href="#"
            className={`absolute bottom-[-5px] left-1 mb-2 font-instrument ${linkArea} text-primary px-4 py-2 rounded-lg opacity-0 transition-opacity duration-300  ${
              hovered ? `${linkClass} opacity-100` : ""
            }`}
          >
            {link}
          </a>
        )}
      </div>
    </div>
  );
};

export default Card;
