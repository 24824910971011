
import React from 'react';

const ProcessCard = ({ title, description, imgSrc, customWidth }) => {
  return (
    <div className={`bg-white text-black p-6 rounded-lg shadow-md ${customWidth}`}>
      <img src={imgSrc} alt={title} className="w-full  object-fit mb-4 rounded-md " />
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default ProcessCard;
