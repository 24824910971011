import React from 'react';

const SadCircleIcon = ({ width = '40px', height = '40px', strokeColor = '#1C274C', fillColor = '#1C274C' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M9 17C9.85038 16.3697 10.8846 16 12 16C13.1154 16 14.1496 16.3697 15 17"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <ellipse
      cx="15"
      cy="10.5"
      rx="1"
      ry="1.5"
      fill={fillColor}
    />
    <ellipse
      cx="9"
      cy="10.5"
      rx="1"
      ry="1.5"
      fill={fillColor}
    />
  </svg>
);

export default SadCircleIcon;
