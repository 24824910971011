import React from 'react';
import { Link } from 'react-router-dom';

function BreadCrumbs({ path }) {

  const pathnames = path.split(' / ').map(item => item.trim()).filter(item => item);

  return (
    <nav aria-label="breadcrumb" className='lg:mt-[130px]  flex flex-col justify-center lg:w-11/12 mx-auto'>
      <ol className="flex space-x-2 text-sm text-gray-600">
        {pathnames.map((value, index) => (
          <li key={index} className="flex items-center">
            {index > 0 && <span className="mx-2 ">/</span>}
            {index === pathnames.length - 1 ? (
           
              <span className="text-[#4f4f4f] font-semibold">{value}</span>
            ) : (
              
              <Link to="#" className="capitalize text-[#b0b0b0]">
                {value}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default BreadCrumbs;
