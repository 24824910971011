import React, { useState } from 'react';

const ToggleCard = ({ icon, title, description, hovered }) => {
  const [isHovered, setIsHovered] = useState(false);


  return (
    <div
      className="lg:h-56 mt-8 mx-4"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full h-full bg-white text-black rounded-xl transition-transform transform hover:scale-105 hover:shadow-custom-shadow flex items-center p-4">
        <div>
          <div className="text-orange-500 text-3xl mb-2">
            {isHovered ? hovered : icon}
          </div>
          <h3 className="text-xl font-bold font-cabinet mb-2">{title}</h3>
          <p className="font-instrument text-base font-regular">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ToggleCard;
