import { useState } from 'react';
import Accordion from '../Accordin/Accordin'; 

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const data = [
    {
      title: 'What does a UX service designer do?',
      content:
        'UX service designer emphasises on developing a customer-centric design strategy, performs extensive user research, builds user personas, designs wireframes, creates prototypes and ensure carrying out user testing to make sure the final product offers an unparalleled customer experience. With the core objective of understanding the customer’s journey and making a product that contributes to these needs, the results must improve customer engagement and satisfaction. UX service designers adapt to your unique requirements and offer stunning design solutions that align with your brand. ',
    },
    {
      title: 'What kind of application do you design?',
      content: 'Brief description for Ideation/Brainstorming.',
    },
    {
      title: 'Wireframes',
      content: 'Brief description for Wireframes.',
    },
    {
      title: 'At what point should a UI/UX audit be carried out?',
      content: 'Brief description for Design.',
    },
    {
      title: 'Can you perform a UX audit for my website or mobile application?',
      content: 'Brief description for High-Fidelity Prototyping.',
    },
    {
      title: 'How will UI/UX consulting services help my product or service?',
      content: 'Brief description for High-Fidelity Prototyping.',
    },
  
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="lg:w-11/12 mx-auto py-10 px-5">
      <h2 className="text-center text-3xl font-medium mb-6 font-cabinet">FAQ</h2>

      {data.map((item, index) => (
        <Accordion
          key={index}
          title={item.title}
          content={item.content}
          isActive={activeIndex === index}
          onClick={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
};

export default Faq;
