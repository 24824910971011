import React, { useState } from "react";
import ToggleCard from "../cards/ToggleCard";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { doData, notDone, workData } from "./data";
import Button from "../Button/Button";

const DowithUs = () => {
  const [isToggled, setIsToggled] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleToggle = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsToggled((prevState) => !prevState);
      setIsTransitioning(false);
    }, 300); 
  };

  const currentCards = isToggled ? doData : notDone;

  return (
    <div className="flex flex-col items-center justify-center lg:py-10 py-20 text-white lg:w-11/12 mx-auto">
      <div className="block lg:hidden text-center mb-8">
        <div className="flex flex-col items-center gap-y-2">
          <h1
            className={`lg:text-5xl text-4xl font-medium ${
              isToggled ? "text-gradient-radial" : "text-black"
            } font-cabinet mb-2`}
          >
            {workData.titleOne}{" "}
            {isToggled ? (
              <span className="text-black">Do</span>
            ) : (
              <span className="text-black">Don't</span>
            )}
          </h1>
          <ToggleSwitch isActive={isToggled} onToggle={handleToggle} />
          <h2
            className={`lg:text-5xl text-4xl font-medium font-cabinet ${
              isToggled ? "text-gradient-radial" : "text-black"
            }`}
          >
            {workData.titleTwo}
          </h2>
        </div>
      </div>

      <div className="hidden lg:block text-center mb-8">
        <h1
          className={`lg:text-5xl text-4xl font-medium ${
            isToggled ? "text-gradient-radial" : "text-black"
          } font-cabinet mb-2`}
        >
          {workData.titleOne}
        </h1>
        <div className="flex items-center justify-center gap-x-4">
          <ToggleSwitch isActive={isToggled} onToggle={handleToggle} />
          <h2
            className={`lg:text-5xl text-4xl font-medium font-cabinet ${
              isToggled ? "text-gradient-radial" : "text-black"
            }`}
          >
            {workData.titleTwo}
          </h2>
        </div>
      </div>

      <div
        className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:gap-8 gap-3 transition-opacity duration-500 transform ${
          isTransitioning ? "opacity-0" : "opacity-100"
        }`}
      >
        {currentCards.map((card, index) => (
          <ToggleCard
            key={index}
            icon={card.icon}
            hovered={card.hovered}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>

      <div className="mt-10">
        <Button className="mt-8 bg-primary text-white rounded-full" label={workData.buttonText} curve="lg" />
      </div>
    </div>
  );
};

export default DowithUs;
