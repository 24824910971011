import React from 'react';

const ResponsiveIcon = ({ color = '#0A0A0A', strokeColor = '#000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M7.66666 16V8V16ZM7.66666 8H15.6667H7.66666ZM7.66666 8L15.6667 16L7.66666 8ZM31.6667 16V8V16ZM31.6667 8H23.6667H31.6667ZM31.6667 8L23.6667 16L31.6667 8ZM7.66666 24V32V24ZM7.66666 32H15.6667H7.66666ZM7.66666 32L15.6667 24L7.66666 32ZM31.6667 32L23.6667 24L31.6667 32ZM31.6667 32V24V32ZM31.6667 32H23.6667H31.6667Z"
        fill={color}
      />
      <path
        d="M7.66666 16V8M7.66666 8H15.6667M7.66666 8L15.6667 16M31.6667 16V8M31.6667 8H23.6667M31.6667 8L23.6667 16M7.66666 24V32M7.66666 32H15.6667M7.66666 32L15.6667 24M31.6667 32L23.6667 24M31.6667 32V24M31.6667 32H23.6667"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResponsiveIcon;
