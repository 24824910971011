import AngleTag from "../../assets/icons/AngleTag";
import CancelIcon from "../../assets/icons/CancelIcon";
import CopyIcon from "../../assets/icons/CopyIcon";
import DateIcon from "../../assets/icons/DateIcon";
import EditIcon from "../../assets/icons/EditIcon";
import EyeSlashIcon from "../../assets/icons/EyeSlashIcon";
import ResponsiveIcon from "../../assets/icons/ResponsiveIcon";
import SadCircleIcon from "../../assets/icons/SadCircleIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import ThumbIcon from "../../assets/icons/ThumbIcon";
import TickIcon from "../../assets/icons/TickIcon";
import WarningIcon from "../../assets/icons/WarnningIcon";

export const doData = [
  {
    icon: <EditIcon color="#000" />,
    hovered: <EditIcon color="#FF3D00" />,
    title: "Creative Expertise",
    description:
      "Crafting bespoke websites that meet your specific needs, enhancing functionality and user experience.",
  },
  {
    icon: <ResponsiveIcon color="#000" />,
    hovered: <ResponsiveIcon color="#FF3D00" strokeColor="#FF3D00" />,
    title: "Responsive Designs",
    description:
      "Ensuring seamless experiences across all devices, maximizing user engagement and satisfaction.",
  },
  {
    icon: <SearchIcon color="#000" />,
    hovered: <SearchIcon color="#FF3D00" fillColor2="#FF3D00" />,
    title: "SEO Optimization",
    description:
      "Elevating your online presence with strategies that boost search engine rankings effectively.",
  },
  {
    icon: <AngleTag color="#000" />,
    hovered: <AngleTag color="#FF3D00" />,
    title: "Custom Solutions",
    description:
      "Crafting bespoke websites that meet your specific needs, enhancing functionality and user experience.",
  },
  {
    icon: <ThumbIcon color="#000" />,
    hovered: <ThumbIcon color="#FF3D00" />,
    title: "Quick Turnaround",
    description:
      "Meeting deadlines consistently, providing reliable services without compromising quality or efficiency.",
  },
  {
    icon: <TickIcon color="#000" />,
    hovered: <TickIcon color="#FF3D00" />,
    title: "Exceptional Support",
    description:
      "Dedicated assistance during and after project completion, ensuring client satisfaction and success.",
  },
];

export const notDone = [
  {
    icon: <CopyIcon fillColor="#000" />,
    hovered: <CopyIcon fillColor="#FF3D00" />,
    title: "Expertise",
    description:
      "Settling for uninspired templates,failing to differentiate your brand in a crowded market.",
  },
  {
    icon: <CancelIcon fillColor="#000" />,
    hovered: <CancelIcon fillColor="#FF3D00" />,
    title: "Poor User Experience",
    description:
      "Team not working together well, making it hard to achive common goals and tasks.",
  },
  {
    icon: <EyeSlashIcon strokeColor="#000" />,
    hovered: <EyeSlashIcon strokeColor="#FF3D00" />,
    title: "Low Visibility",
    description:
      "Bad vibes at work people unhappy, lowering how much work gets done.",
  },
  {
    icon: <SadCircleIcon fillColor="#000" strokeColor="#000" />,
    hovered: <SadCircleIcon fillColor="#FF3D00" strokeColor="#FF3D00" />,
    title: "Limited Functionality",
    description:
      "People doing the wrong jobs aren't happy,and it makes the work not so good.",
  },
  {
    icon: <DateIcon />,
    hovered: <DateIcon  primaryStrokeColor="#FF3D00"/>,
    title: "Missed Deadlines",
    description:
      "Work processes are done poorly,taking too much time and money due to inefficicient methords.",
  },
  {
    icon: <WarningIcon />,
    hovered: <WarningIcon fillColor="#FF3D00" />,
    title: "Inadequare Support",
    description:
      "People argue a lot, making it tough for the team to stay together and work well",
  },
];

export const workData = {
  titleOne: "When People",
  titleTwo: "Work With Us",
  buttonText: "Let's build something",
};
