import React from "react";
import Layout from "../layout/Layout";
import HeroSection from "../components/HeroSection/HeroSection";
import ActivitiesSections from "../components/Activitys/ActivitiesSection";
import DoWithUs from "../components/DoWithUs/DoWithUs";
import ProcessCard from "../components/ProcessCard/ProcessCard";
import StoryArea from "../components/StoryArea/StoryArea";
import JourneySection from "../components/journeySection/JourneySection";
import Creations from "../components/creations/CreationArea";
import JoinUs from "../components/joinUs/JoinUs";

const LandingPage = () => {
  return (
    <Layout>
      <HeroSection />
      <ActivitiesSections />
      <DoWithUs />
      <ProcessCard />
      <StoryArea />
      <JourneySection />
      <Creations />
      <JoinUs />
    </Layout>
  );
};

export default LandingPage;
