// IconComponent.js
import React from 'react';

const EditIcon = ({ color = 'color' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path 
      d="M34.828 5.17115C34.0779 4.42126 33.0607 4 32 4C30.9393 4 29.9221 4.42126 29.172 5.17115L14 20.3431V25.9991H19.656L34.828 10.8271C35.5779 10.077 35.9991 9.0598 35.9991 7.99915C35.9991 6.93849 35.5779 5.92126 34.828 5.17115Z" 
      fill={color}
    />
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M4 12C4 10.9391 4.42143 9.92172 5.17157 9.17157C5.92172 8.42143 6.93913 8 8 8H16C16.5304 8 17.0391 8.21071 17.4142 8.58579C17.7893 8.96086 18 9.46957 18 10C18 10.5304 17.7893 11.0391 17.4142 11.4142C17.0391 11.7893 16.5304 12 16 12H8V32H28V24C28 23.4696 28.2107 22.9609 28.5858 22.5858C28.9609 22.2107 29.4696 22 30 22C30.5304 22 31.0391 22.2107 31.4142 22.5858C31.7893 22.9609 32 23.4696 32 24V32C32 33.0609 31.5786 34.0783 30.8284 34.8284C30.0783 35.5786 29.0609 36 28 36H8C6.93913 36 5.92172 35.5786 5.17157 34.8284C4.42143 34.0783 4 33.0609 4 32V12Z" 
      fill={color}
    />
  </svg>
);

export default EditIcon;
