
import travelImage from "../../assets/images/creations/travel.png";
import foodingImage from "../../assets/images/creations/fooding.png";
import waterImage from "../../assets/images/creations/water.png";


export const case_studies = [
  {
    id: 1,
    image: travelImage,
    title: "A page for a travel website",
    description:
      "We designed and built a platform for a travel company to promote their packages and online presence. This will result in successful business growth.",
    link:'Learn more ➛'  
  },
  {
    id: 2,
    image: foodingImage,
    title: "A food mess management Web app",
    description: `We designed and built a Web application for an ongoing food mess. This enables them to monitor and manage their business.`,
    link:'Learn more ➛' 
  },
  {
    id: 3,
    image: waterImage,
    title: "Water can delivery system",
    description:
      "We designed an app to manage the delivery and management of water cans for water can vendors.",
      link:'Learn more ➛' 
  },
];

export const details = [
  { title: "Case studies" },
 
];






