import React from 'react';
import Card from '../cards/Cards';
import { activities, details } from './data';

const RelatedServices = () => {
  return (
    <section className="lg:py-10  w-11/12 mx-auto">
      <div className="container mx-auto text-center">
        <h2 className="lg:text-5xl text-4xl font-medium mb-4 text-gradient-radial font-cabinet">{details[0].title}</h2>
      
        <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-16 gap-8">
          {activities.map(activity => (
            <Card
              key={activity.id}
              image={activity.image}
              title={activity.title}
              description={activity.description}
              link={activity.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RelatedServices;
