import React from 'react';

const SearchIcon = ({ color = '#0A0A0A', fillColor2 = '#000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M18.3334 18C18.3334 16.9391 18.7548 15.9217 19.5049 15.1716C20.2551 14.4214 21.2725 14 22.3334 14C23.3942 14 24.4117 14.4214 25.1618 15.1716C25.9119 15.9217 26.3334 16.9391 26.3334 18C26.3334 19.0609 25.9119 20.0783 25.1618 20.8284C24.4117 21.5786 23.3942 22 22.3334 22C21.2725 22 20.2551 21.5786 19.5049 20.8284C18.7548 20.0783 18.3334 19.0609 18.3334 18Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3334 36C24.5768 36 28.6465 34.3143 31.6471 31.3137C34.6477 28.3131 36.3334 24.2435 36.3334 20C36.3334 15.7565 34.6477 11.6869 31.6471 8.68629C28.6465 5.68571 24.5768 4 20.3334 4C16.0899 4 12.0202 5.68571 9.01967 8.68629C6.01908 11.6869 4.33337 15.7565 4.33337 20C4.33337 24.2435 6.01908 28.3131 9.01967 31.3137C12.0202 34.3143 16.0899 36 20.3334 36ZM22.3334 10C20.9227 9.9998 19.5371 10.3726 18.3169 11.0806C17.0968 11.7887 16.0856 12.8068 15.3859 14.0316C14.6861 15.2565 14.3227 16.6446 14.3324 18.0553C14.3422 19.4659 14.7248 20.8489 15.4414 22.064L10.9194 26.584C10.5441 26.959 10.3332 27.4678 10.333 27.9983C10.3329 28.261 10.3845 28.5211 10.485 28.7639C10.5854 29.0066 10.7327 29.2272 10.9184 29.413C11.1041 29.5988 11.3245 29.7462 11.5672 29.8469C11.8099 29.9475 12.07 29.9993 12.3327 29.9994C12.8632 29.9996 13.3721 29.789 13.7474 29.414L18.2694 24.892C19.3291 25.5166 20.5185 25.8884 21.7453 25.9785C22.972 26.0686 24.203 25.8747 25.3426 25.4117C26.4822 24.9487 27.4997 24.2292 28.316 23.309C29.1323 22.3888 29.7254 21.2928 30.0492 20.1062C30.373 18.9195 30.4189 17.6742 30.1832 16.4669C29.9474 15.2596 29.4365 14.123 28.6901 13.1453C27.9436 12.1676 26.9818 11.3753 25.8793 10.8297C24.7768 10.2842 23.5634 10.0003 22.3334 10Z"
        fill={fillColor2}
      />
    </svg>
  );
};

export default SearchIcon;
