import React from 'react';

const HamburgerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    viewBox="0 0 48 48"
    fill="none"
    className="w-6 h-6" // Adjust the size as needed
  >
    <rect width="56" height="56" fill="white" fillOpacity="0.01" />
    <path
      d="M7.94977 11.9498H39.9498"
      stroke="#FF3D00"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.94977 23.9498H39.9498"
      stroke="#FF3D00"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.94977 35.9498H39.9498"
      stroke="#FF3D00"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HamburgerIcon;
