import QupeStar from "../../assets/icons/QupeStar";

export const joinData = {
  title: "Join Us",
  desc: (
    <span className="lg:inline-flex gap-2">
      Let's Create Something Amazing Together <QupeStar />
    </span>
  ),
  colorDesc: (
    <>
      Are you ready to dive into the world of innovation and creativity? We're on a mission to shake things up and make a mark in the tech world. If you're passionate, driven, and ready to grow with us, we want you on our team! Bring your ideas, energy, and fresh perspectives, and let’s create something extraordinary together.
    </>
  ),
};
