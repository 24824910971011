import React from 'react';
import AppRoutes from './routes/AppRouter';
import Chat from './components/Chat/Chat'

function App() {
  return (
    <div className="App">
      <AppRoutes />
      <Chat/>
    </div>
  );
}

export default App;
