import React from "react";
import { footerData } from "./data";

const Footer = () => {
  return (
    <footer className="bg-[#181818] text-white py-20 mt-auto font-instrument">
      <div className="container mx-auto w-11/12">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mb-8">
         
          <div className="flex flex-col  items-start ">
            <img src={footerData.logoSrc} alt="Logo" className="h-8 w-auto mb-4" />
            <p className="text-lg font-regular lg:text-start md:text-left leading-8">
              {footerData.address}
            </p>
            <p className="text-sm lg:text-start text-left mt-5">
              Email Us: {footerData.email}
            </p>
          </div>

          <div className="flex flex-col lg:items-start text-start md:items-start">
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            {footerData.companyLinks.map((link, index) => (
              <p key={index} className="text-sm text-start mb-3">{link}</p>
            ))}
          </div>

          <div className="flex flex-col lg:items-start text-start md:items-start">
            <h3 className="text-lg font-semibold mb-4">Services</h3>
            <ul className="list-none">
              {footerData.services.map((service, index) => (
                <li key={index} className="text-start mb-3">
                  <a href="#" className="hover:underline">
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col lg:items-start  md:items-start">
            <h3 className="text-lg font-semibold mb-4">Social</h3>
            <ul className="list-none">
              {footerData.socialLinks.map((social, index) => (
                <li key={index} className="text-start mb-3">
                  <a href="#" className="hover:underline">
                    {social}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <hr className="border-gray-600 mb-8" />

        <div className="text-sm lg:text-center flex lg:flex-row flex-col justify-between">
          <p className="mb-2">&copy; {footerData.companyName}©2024 All rights reserved</p>
          <p className="mb-1">
            <a href={footerData.termsUrl} className="hover:underline">
              Terms of Service
            </a>
          </p>
          <p>
            <a href={footerData.privacyUrl} className="hover:underline">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
