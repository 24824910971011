import FooterLogo from "../../assets/images/footer-logo.png"


export const footerData = {
      logoSrc: FooterLogo,
      address: "47/764- D, 1st Floor, Villiadath Building, Chalikkavattom, Vennala P.O, Cochin, Ernakulam, Kerala",
      email: "info@botx.co.in",
      companyLinks: ["What We Do", "Our Story", "Portfolio", "Join Us", "Contact Us"],
      services: ["Design", "Develop", "Marketing"],
      socialLinks: ["Linkedin", "Instagram", "Facebook"],
      companyName: "Botx",
      termsUrl: "#",
      privacyUrl: "#"
    };
    