import React from "react";

const Button = ({
  label,          
  type = "button",
  onClick,
  className = "",
  variant = "primary",
  size = "md",
  curve='sm',
  disabled = false,
  ...props
}) => {
  const baseClasses =
    "inline-flex items-center justify-center font-semibold rounded focus:outline-none transition ease-in-out duration-150";

  const variantClasses = {
    primary: "bg-primary text-white hover:bg-lightprimary ",
    secondary:
      "border-2-btnSecondary border text-btnSecondary hover:bg-lightprimary hover:border-1 hover:border-lightprimary hover:bg-transparent",
  };

  const sizeClasses = {
    sm: "px-3 py-1 text-sm",
    md: "px-4 py-2 text-md",
    lg: "px-6 py-3 text-lg",
  };
  
  const curveClass ={
    sm:'rounded-sm',
    md:'rounded-md',
    lg:'rounded-lg',
    full:'rounded-full',
  }

  const classes = `${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${curveClass[curve]} ${className}`;

  return (
    <button
      type={type}
      onClick={onClick}
      className={classes}
      disabled={disabled}
      {...props}
    >
      {label}   
    </button>
  );
};

export default Button;
