import React, { useState, useEffect } from 'react';

const TitleComponent = () => {
  const words = ['Impactful Experiences', 'Seamless Interactions', 'User Delight', 'Digital Transformation', 'Innovation', 'Business Growth'];
  const [index, setIndex] = useState(0);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsExiting(true); // Start exiting the current text

      const timeout = setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsExiting(false); // Start entering the new text
      }, 100); // Match this with the slide-out duration

      return () => clearTimeout(timeout);
    }, 2000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className=" pt-20 pb-10 lg:w-11/12 mx-auto">
     <div className=' flex items-center justify-center '>
     <h1 className="text-5xl font-Medium inline-block">
        <span className="text-black font-cabinet w-full">We design for &nbsp;</span>
        <span
          className={`text-primary font-cabinet inline-flex transition-transform duration-500 ease-in-out  lg:min-w-[500px] ${
            isExiting ? 'translate-y-full opacity-0' : 'translate-y-0 opacity-100'
          }`}
          aria-live="polite"
        >
          {words[index]}
        </span>
      </h1>
     </div>
      <p className="mt-4 text-xl font-regular text-disabledText text-center">
      In a world where first impressions matter, we ensure yours is unforgettable. Our UI/UX design services are tailored to meet the unique needs of your business, blending creativity and strategy to deliver designs that resonate with your audience. Whether it’s a website, app, or a complex digital platform, we focus on creating user-centric solutions that drive engagement and achieve your goals.
      </p>
    </div>
  );
};

export default TitleComponent;
