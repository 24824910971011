export const heroData = {
  title: "We turn your ideas into scalable solutions.",
  subtitle: "Custom software tailored for the ➛ next big thing.",
  subtitle: (
    <>
      Custom software tailored for the <span className="text-black pt-3">➛</span> next big thing.
    </>
  ),
  description:
    "We specialize in crafting bespoke software solutions that help businesses navigate their growth journey seamlessly.",
  buttonLabels: {
    contact: "Contact us",
    bookCall: "Book a call",
  },

};




