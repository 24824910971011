import React from 'react';

const CloseIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"  
      height="24px" 
      viewBox="0 0 64 64"
      strokeWidth="3"
      stroke="#FF3D00"
      fill="none"
      {...props}
    >
      <line x1="8.06" y1="8.06" x2="55.41" y2="55.94" />
      <line x1="55.94" y1="8.06" x2="8.59" y2="55.94" />
    </svg>
  );
};

export default CloseIcon;
