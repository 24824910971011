
import designImage from "../../assets/images/activities/design.png";
import devImage from "../../assets/images/activities/dev.png";
import marketingImage from "../../assets/images/activities/marketing.png";


export const activities = [

  {
    id: 1,
    image: devImage,
    title: "Develop",
    description: `We build robust and scalable solutions to turn your ideas into reality. Whether it's a dynamic website, a seamless mobile app, or a sophisticated e-commerce platform, our development services cater to all your technical needs.',`,
    link:'Learn more ➛' 
  },
  {
    id: 2,
    image: marketingImage,
    title: "Digital Marketing",
    description:
      "We help your brand shine in the digital landscape. Our marketing services drive engagement and growth through strategic social media campaigns, SEO, SEM, and more, ensuring your brand reaches its target audience effectively.",
      link:'Learn more ➛' 
  },
];

export const details = [
  { title: "Related Services" },
 
];
