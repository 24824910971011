
import React from 'react';
import Button from '../Button/Button';
import { StoryData } from "./data";

const StoryArea = () => {
  return (
    <section className="lg:py-20 w-11/12 mx-auto">
      <div className="container mx-auto text-center">
        <h2 className="lg:text-5xl text-4xl font-medium mb-4 text-gradient-radial font-cabinet">{StoryData.title}</h2>
        <p className="lg:text-xl text-lg lg:mb-8 mb-10 font-instrument">{StoryData.desc}</p>
        <p className="text-xl lg:mb-8 mb-10 font-instrument text-gradient-radial">{StoryData.colorDesc}</p>
        <Button variant="secondary" label={'Read more'} curve='lg'/>
      </div>
    </section>
  );
};

export default StoryArea;
