import React from "react";
import Button from "../Button/Button";
import { joinData } from "./data";

const JoinUs = () => {
  return (
    <section className="lg:pt-0 lg:pb-20 w-11/12 mx-auto bg-small-ring bg-no-repeat bg-center min-h-[600px] flex flex-col justify-start items-start">
      <div className="container mx-auto text-center lg:mt-20 mt-0">
        <h2 className="lg:text-5xl text-4xl font-medium mb-4 text-gradient-radial font-cabinet">
          {joinData.title}
        </h2>
        <p className="lg:text-xl text-lg lg:mb-8 mb-10 font-instrument">
          {joinData.desc}
        </p>
        <p className="text-xl lg:mb-8 mb-10 font-instrument text-discription">
          {joinData.colorDesc}
        </p>
        <div className="lg:flex lg:flex-row flex flex-col gap-4 justify-center items-center mb-10 lg:mb-0">
          <Button
            variant="secondary"
            label={"Drop your resume"}
            curve="lg"
            className="order-2 lg:order-1 lg:w-auto w-1/2"
          />
          <Button
            variant="primary"
            label={"Current Oppening"}
            curve="lg"
            className="order-1 lg:order-2 lg:w-auto w-1/2"
          />
        </div>
      </div>
    </section>
  );
};

export default JoinUs;
