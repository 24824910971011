
import image from "../../assets/images/sicon.png";

export const scrollingItems = [
  { text: "UI/UX Design", img: image },
  { text: " Design Consulting", img: image },
  { text: "Web Development", img: image },
  { text: " Mobile App Development", img: image },
  { text: "IT Consulting", img: image },
  { text: "Social Media Marketing", img: image },
];
