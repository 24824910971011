import React from 'react';

const QupeStar = ({ color = '#FF3D00' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M9.19354 14.0627C9.11542 13.7599 8.95759 13.4836 8.73646 13.2624C8.51532 13.0413 8.23898 12.8835 7.93617 12.8053L2.56804 11.4211C2.47646 11.3951 2.39585 11.3399 2.33845 11.264C2.28105 11.188 2.25 11.0954 2.25 11.0002C2.25 10.905 2.28105 10.8124 2.33845 10.7365C2.39585 10.6605 2.47646 10.6053 2.56804 10.5793L7.93617 9.19422C8.23887 9.11617 8.51515 8.95847 8.73627 8.7375C8.95739 8.51653 9.11528 8.24037 9.19354 7.93772L10.5778 2.56959C10.6035 2.47765 10.6586 2.39664 10.7347 2.33894C10.8108 2.28123 10.9036 2.25 10.9991 2.25C11.0946 2.25 11.1874 2.28123 11.2635 2.33894C11.3396 2.39664 11.3947 2.47765 11.4204 2.56959L12.8038 7.93772C12.8819 8.24053 13.0397 8.51688 13.2609 8.73801C13.482 8.95914 13.7584 9.11697 14.0612 9.19509L19.4293 10.5785C19.5216 10.6039 19.603 10.659 19.661 10.7352C19.719 10.8113 19.7505 10.9045 19.7505 11.0002C19.7505 11.096 19.719 11.1891 19.661 11.2653C19.603 11.3415 19.5216 11.3965 19.4293 11.422L14.0612 12.8053C13.7584 12.8835 13.482 13.0413 13.2609 13.2624C13.0397 13.4836 12.8819 13.7599 12.8038 14.0627L11.4195 19.4308C11.3938 19.5228 11.3387 19.6038 11.2626 19.6615C11.1866 19.7192 11.0937 19.7504 10.9982 19.7504C10.9027 19.7504 10.8099 19.7192 10.7338 19.6615C10.6578 19.6038 10.6026 19.5228 10.5769 19.4308L9.19354 14.0627Z"
      fill={`url(#paint0_radial_${color})`}
    />
    <path d="M18 3.125V6.625V3.125Z" fill={color} />
    <path
      d="M18 3.125V6.625"
      stroke={`url(#paint1_radial_${color})`}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19.75 4.875H16.25H19.75Z" fill={color} />
    <path
      d="M19.75 4.875H16.25"
      stroke={`url(#paint2_radial_${color})`}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 15.375V17.125V15.375Z" fill={color} />
    <path
      d="M4 15.375V17.125"
      stroke={`url(#paint3_radial_${color})`}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.875 16.25H3.125H4.875Z" fill={color} />
    <path
      d="M4.875 16.25H3.125"
      stroke={`url(#paint4_radial_${color})`}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <radialGradient
        id={`paint0_radial_${color}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.0002 11.0002) scale(8.75023 8.75022)"
      >
        <stop stopColor={color} />
        <stop offset="1" stopColor="#FFB800" />
      </radialGradient>
      <radialGradient
        id={`paint1_radial_${color}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.5 4.875) scale(0.5 1.75)"
      >
        <stop stopColor={color} />
        <stop offset="1" stopColor="#FFB800" />
      </radialGradient>
      <radialGradient
        id={`paint2_radial_${color}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18 5.375) scale(1.75 0.5)"
      >
        <stop stopColor={color} />
        <stop offset="1" stopColor="#FFB800" />
      </radialGradient>
      <radialGradient
        id={`paint3_radial_${color}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.5 16.25) scale(0.5 0.875)"
      >
        <stop stopColor={color} />
        <stop offset="1" stopColor="#FFB800" />
      </radialGradient>
      <radialGradient
        id={`paint4_radial_${color}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4 16.75) scale(0.875 0.5)"
      >
        <stop stopColor={color} />
        <stop offset="1" stopColor="#FFB800" />
      </radialGradient>
    </defs>
  </svg>
);

export default QupeStar;
