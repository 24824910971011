import React from "react";

const ToggleSwitch = ({ isActive, onToggle }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className={`mr-3 ${isActive ? "text-gradient-radial":"text-black"}  lg:text-5xl text-4xl hidden lg:block`}>
        {isActive ? "Do" : "Don’t"}
      </div>
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isActive}
          onChange={onToggle}
        />
        <div
          className={`block lg:w-24 lg:h-14  w-16 h-8 rounded-full transition-colors duration-300 ${
            isActive ? "  bg-primary " : " bg-[#fff] shadow-full-inner"
          }`}
        ></div>
        <div
          className={`dot absolute lg:top-2 top-1 lg:left-2 left-1  lg:w-10 lg:h-10 w-6 h-6 rounded-full shadow-toggle-shadow transition-transform duration-300 ease-in-out ${
            isActive ? "transform lg:translate-x-[2.7rem] translate-x-[1.8rem] shadow-full-inner bg-white " : "shadow-full-inner bg-[#DCD7D7]" 
          }`}
        ></div>
      </div>
    </label>
  );
};

export default ToggleSwitch;
