import React from "react";
import "./style.css"
const FreeConsultationForm = () => {
  return (
    <div className="lg:w-11/12 mx-auto px-4 py-10">
      <h2 className="text-4xl font-medium font-cabinet  mb-4">
        Grab a chance to get free IT consultation
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <ol className="list-decimal list-inside  custom-list">
            <li>
              An expert will contact you after analyzing your requirements.
            </li>
            <li>
              If needed, we will sign an NDA to ensure the highest privacy
              level.
            </li>
            <li>
              We will submit a detailed project proposal to develop custom
              software for you.
            </li>
            <li>Project kickoff and development commencement.</li>
          </ol>
        </div>

        <form className="space-y-6 font-sans">
          <div>
            <label
              htmlFor="industry"
              className="block text-sm font-medium text-gray-700"
            >
              Select your industry type
            </label>
            <select
              id="industry"
              name="industry"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              required
            >
              <option>Select your industry</option>
              <option>IT</option>
              <option>Healthcare</option>
              <option>Finance</option>
              <option>Other</option>
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Company mail ID
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                required
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              required
            />
            <small className="text-gray-500">
              We will call you ASAP or you can{" "}
              <a href="#" className="text-black font-bold underline">
                schedule a call
              </a>
              .
            </small>
          </div>

          <div>
            <label
              htmlFor="requirements"
              className="block text-sm font-medium text-gray-700"
            >
              Describe your project requirements
            </label>
            <textarea
              id="requirements"
              name="requirements"
              rows="4"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full md:w-auto px-6 py-2 bg-primary text-white font-semibold rounded-md hover:bg-orange-600 transition-colors"
          >
            Send request
          </button>
        </form>
      </div>
    </div>
  );
};

export default FreeConsultationForm;
