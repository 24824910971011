
import card1 from "../../assets/images/process/card1.png"
import card2 from "../../assets/images/process/card2.png"
import card3 from "../../assets/images/process/card3.png"
import card4 from "../../assets/images/process/card4.png"
import card5 from "../../assets/images/process/card5.png"


export const processCards = [
      {
        title: "First meeting",
        description: "Trust is extremely important to us when working together. In our initial meeting, we get to know each other and see if we are a good fit. We also talk about your goals and requirements, clarify questions, and define the next steps. ",
        imgSrc: card1,
        customWidth: "w-full sm:w-[40%]", 
      },
      {
        title: "Estimate",
        description: "We promptly provide a Statement of Work within 24 hours of project understanding. Our services cover web app development, deployment, and QA.",
        imgSrc: card2,
        customWidth: "w-full sm:w-[60%]", 
      },
      {
        title: "Meetings",
        description: "Following project estimation, we convene with developers and clients for a strategy meeting, aligning on timelines and finalizing changes before deployment.",
        imgSrc: card3,
        customWidth: "w-full",
      },
      {
        title: "Implementation",
        description: "As soon as we receive everything we need, we can begin the initiation process. Ensuring open communication and frequent updates is our top concern.",
        imgSrc: card4,
        customWidth: "w-full", 
      },
      {
        title: "Launch day",
        description: "The completed website will undergo quality assurance testing before launch to make sure every little aspect is perfect.",
        imgSrc: card5,
        customWidth: "w-full", 
      },
    ];
    

export const progressDetails = [
  {
    title:"Our Process",
    description:"Our workflow to make sure you get what you're looking for."
  }
]    