
import QupeStar from "../../assets/icons/QupeStar";
import designImage from "../../assets/images/activities/design.png";
import devImage from "../../assets/images/activities/dev.png";
import marketingImage from "../../assets/images/activities/marketing.png";


export const activities = [
  {
    id: 1,
    image: designImage,
    title: "UI/UX Design",
    description:
      "We bring your vision to life with cutting-edge design solutions. From creating intuitive interfaces to stunning animations, we ensure every visual element enhances user experience and reinforces your brand.",
    link:'Learn more ➛'  
  },
  {
    id: 2,
    image: devImage,
    title: "Develop",
    description: `We build robust and scalable solutions to turn your ideas into reality. Whether it's a dynamic website, a seamless mobile app, or a sophisticated e-commerce platform, our development services cater to all your technical needs.',`,
    link:'Learn more ➛' 
  },
  {
    id: 3,
    image: marketingImage,
    title: "Digital Marketing",
    description:
      "We help your brand shine in the digital landscape. Our marketing services drive engagement and growth through strategic social media campaigns, SEO, SEM, and more, ensuring your brand reaches its target audience effectively.",
      link:'Learn more ➛' 
  },
];

export const details = [
  { title: "What We Do" },
  {
    desc: (<span className="lg:inline-flex flex flex-col justify-center items-center gap-1"> Where imagination meets innovation,<QupeStar/>making magic happen </span>),
  },
];
