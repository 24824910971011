import React from "react";
import Button from "../Button/Button";
import { heroData } from "./HeroData";
import TextScrolling from "../scrolling/TextScrolling";

const HeroSection = () => {
  return (
    <section className="text-white py-20 min-h-screen flex flex-col items-center bg-hero-image bg-cover bg-center h-[500px] w-full">
      <div className="container flex flex-col justify-center items-center mx-auto flex-grow mt-10 lg:mt-0">
        <div className="lg:leading-15">
          <div className="lg:text-5xl text-3xl font-medium text-title font-cabinet text-center">
            {heroData.title}
          </div>
          <div className="lg:text-5xl text-3xl font-medium text-primary font-cabinet text-center">
            {heroData.subtitle}
          </div>
        </div>
        <p className="mt-4 lg:text-xl text-lg font-instrument text-center text-desc font-regular leading-7 p-2 lg:p-0">
          {heroData.description}
        </p>
        <div className="mt-20 flex gap-4">
          <Button
            variant="secondary"
            size="md"
            label={heroData.buttonLabels.contact}
            curve="lg"
          />
          <Button
            variant="primary"
            size="md"
            label={heroData.buttonLabels.bookCall}
            curve="lg"
          />
        </div>
      </div>
      <div className="w-full flex justify-center mt-10 lg:mt-0">
        <TextScrolling />
      </div>
    </section>
  );
};

export default HeroSection;
