
import React from 'react';

const Popup = ({ message, onClose }) => {
  return (
    <div className="fixed bottom-20 right-4 p-4 bg-white text-discription rounded-md shadow-lg w-80 leading-7">
      <p>{message}</p>
      <button
        onClick={onClose}
        className="absolute top-0 right-2 text-gray-400 hover:text-gray-600"
        aria-label="Close popup"
      >
        &times;
      </button>
    </div>
  );
};

export default Popup;
