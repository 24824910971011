import React from 'react'
import Layout from "../layout/Layout";
import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs';
import WedoHero from '../components/WedoHero/WedoHero';
import UIUXExpertise from '../components/UI&UXExpresite/UIandUXExpresite';
import Process from '../components/Process/Process';
import CaseStudies from '../components/CaseStudies/CaseStudies';
import RelatedServices from '../components/RelatedServices/RelatedServices';
import Faq from '../components/FAQ/Faq';
import FreeConsultationForm from '../components/Consultation/Consultation';
function WhatWeDo() {
  return (
      <Layout>
        <BreadCrumbs path="What we do / UI&nbsp;/&nbsp;UX Design" />
            <WedoHero/>
            <UIUXExpertise/>
            <Process/>
            <CaseStudies/>
            <RelatedServices/>
            <Faq/>
            <FreeConsultationForm/>

      </Layout>
  )
}

export default WhatWeDo