import React from "react";

const ThumbIcon = ({ color = "#0A0A0A" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M4.66666 21C4.66666 20.606 4.74425 20.2159 4.89502 19.8519C5.04578 19.488 5.26676 19.1573 5.54534 18.8787C5.82391 18.6001 6.15463 18.3791 6.51861 18.2284C6.88258 18.0776 7.27269 18 7.66666 18C8.06062 18 8.45073 18.0776 8.81471 18.2284C9.17868 18.3791 9.5094 18.6001 9.78798 18.8787C10.0666 19.1573 10.2875 19.488 10.4383 19.8519C10.5891 20.2159 10.6667 20.606 10.6667 21V33C10.6667 33.7956 10.3506 34.5587 9.78798 35.1213C9.22537 35.6839 8.46231 36 7.66666 36C6.87101 36 6.10795 35.6839 5.54534 35.1213C4.98273 34.5587 4.66666 33.7956 4.66666 33V21ZM12.6667 20.666V31.526C12.6663 32.2693 12.8731 32.998 13.2638 33.6304C13.6545 34.2628 14.2137 34.7737 14.8787 35.106L14.9787 35.156C16.0884 35.7107 17.312 35.9996 18.5527 36H29.3847C30.3097 36.0004 31.2063 35.6801 31.9218 35.0937C32.6372 34.5073 33.1273 33.6911 33.3087 32.784L35.7087 20.784C35.8246 20.2037 35.8104 19.605 35.6671 19.0309C35.5237 18.4568 35.2547 17.9216 34.8796 17.464C34.5044 17.0064 34.0324 16.6377 33.4976 16.3846C32.9627 16.1314 32.3784 16.0001 31.7867 16H24.6667V8C24.6667 6.93913 24.2452 5.92172 23.4951 5.17157C22.7449 4.42143 21.7275 4 20.6667 4C20.1362 4 19.6275 4.21071 19.2524 4.58579C18.8774 4.96086 18.6667 5.46957 18.6667 6V7.334C18.6667 9.06496 18.1052 10.7492 17.0667 12.134L14.2667 15.866C13.2281 17.2508 12.6667 18.935 12.6667 20.666Z"
        fill={color}
      />
    </svg>
  );
};

export default ThumbIcon;
