import React, { useState } from 'react';
import HamburgerIcon from '../../assets/icons/HamburgerIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import { menuItems } from './data';
import Logo from "../../assets/images/logo.png";
import Button from '../Button/Button';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  const handleMouseEnter = (itemName) => {
    setHoveredItem(itemName);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <header className={`bg-white text-black p-4 fixed top-5 ${
      isMenuOpen ? ' ' : 'left-1/2 transform -translate-x-1/2'
    } z-50 shadow-md rounded-lg w-11/12`}>
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <img src={Logo} alt="Logo" className="h-8 w-auto" />
        </div>

        <nav className="hidden md:flex flex-grow items-center justify-center space-x-12">
          {menuItems.map((item) => (
            <div
              key={item.name}
              className="relative group"
              onMouseEnter={() => handleMouseEnter(item.name)}
              onMouseLeave={handleMouseLeave}
            >
              <a
                href={item.href}
                className={`block text-black transition-transform ${
                  hoveredItem === item.name ? 'animate-slideUp  ' : ''
                }`}
              >
                {item.name}
              </a>
              <div
                className={`absolute inset-0 flex items-center text-primary justify-center bg-white transition-transform ${
                  hoveredItem === item.name ? 'animate-slideDown' : 'hidden'
                }`}
              >
                <a href={item.href}>{item.swipeList}</a>
              </div>
            </div>
          ))}
        </nav>

        <div className='hidden lg:block'><Button label="Book a call" curve="lg" /></div>

        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-black">
            <HamburgerIcon />
          </button>
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-white text-white p-4 transform transition-transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-[115%]'
        } md:hidden z-40`}
      >
        <div className="flex justify-between items-center">
          <img src={Logo} alt="Logo" className="h-8 w-auto" />
          <button onClick={toggleMenu} className="text-white">
            <CloseIcon />
          </button>
        </div>
        <nav className="mt-4 px-2">
          {menuItems.map((item) => (
            <div
              key={item.name}
              className="relative group"
              onMouseEnter={() => handleMouseEnter(item.name)}
              onMouseLeave={handleMouseLeave}
            >
              <a
                href={item.href}
                className={`block py-2 text-sm text-black transition-transform ${
                  hoveredItem === item.name ? 'animate-slideUp' : ''
                }`}
              >
                {item.name}
              </a>
              <div
                className={`absolute inset-0 flex items-start justify-start bg-white text-primary transition-transform ${
                  hoveredItem === item.name ? 'animate-slideDown' : 'hidden'
                }`}
              >
                {item.swipeList}
              </div>
            </div>
          ))}
        </nav>
        <div className='mt-4 px-2'>  <Button label="Book a call" curve="lg" size='sm'/></div>
      </div>
    </header>
  );
};

export default Header;
